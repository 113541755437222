var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.page)?_c('div',{},[_vm._l((_vm.page.content.breadcrumbs),function(breadcrumbs){return _c('Breadcrumbs',{key:breadcrumbs.id,attrs:{"breadcrumbs":_vm.page.content.breadcrumbs[0].breadcrumbs,"custom-page":true}})}),_vm._v(" "),(!_vm.secretVipPage)?_c('div',{staticClass:"mx-4 lg:mx-14",class:[
      _vm.page.content.mobileBlockSpacing ? 'space-y-' + _vm.page.content.mobileBlockSpacing : 'space-y-8',
      _vm.page.content.desktopBlockSpacing ? 'lg:space-y-' + _vm.page.content.desktopBlockSpacing : 'lg:space-y-24'
    ]},_vm._l((_vm.page.content.main_content),function(block){return _c(block.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}],key:block.id,tag:"component",attrs:{"page-name":_vm.page.content.breadcrumbName || _vm.page.name}},'component',block,false))}),1):_c('ClientOnly',[(_vm.loggedIn)?_c('div',{staticClass:"mx-4 lg:mx-14",class:[
        _vm.page.content.mobileBlockSpacing ? 'space-y-' + _vm.page.content.mobileBlockSpacing : 'space-y-8',
        _vm.page.content.desktopBlockSpacing ? 'lg:space-y-' + _vm.page.content.desktopBlockSpacing : 'lg:space-y-24'
      ]},_vm._l((_vm.page.content.main_content),function(block){return _c(block.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(block),expression:"block"}],key:block.id,tag:"component",attrs:{"page-name":_vm.page.content.breadcrumbName || _vm.page.name}},'component',block,false))}),1):_c('div',{staticClass:"w-full flex flex-wrap-reverse gap-0"},[(_vm.hero)?_c('Hero',_vm._b({staticClass:"w-full lg:w-3/5"},'Hero',_vm.hero,false)):_vm._e(),_vm._v(" "),_c('AccountLogin',{staticClass:"w-full lg:w-2/5 self-center px-8 lg:px-16 py-16",attrs:{"secret-page":true}})],1)])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }