import { mapGetters } from 'vuex'
import AccountLogin from '@/components/account/AccountLogin.vue'
import { getMenuId, menuComponentName } from '@/store/storyblok'

export default {
  name: 'Storyblok',
  components: { AccountLogin },
  props: {
    id: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      meta: {
        title: '',
        meta: [],
        canonical: ''
      }
    }
  },
  head () {
    return {
      title: this.meta.title,
      meta: this.meta.meta,
      link: [
        {
          rel: 'canonical',
          href: this.meta.canonical
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getStoryById: 'storyblok/getStoryById',
      settings: 'storyblok/settings',
      loggedIn: 'member/loggedIn'
    }),
    page () {
      return this.getStoryById(this.id) || this.data
    },
    hero () {
      return this.settings?.account_login_hero?.[0]
    },
    secretVipPage () {
      return this.data.content.secretVipPage
    }
  },
  created () {
    return this.setMeta()
  },
  serverPrefetch () {
    if (this.page.content.centraCampaignSite) {
      this.$store.dispatch('frontend/setCurrentCampaignUri', this.page.content.centraCampaignSite)
    }
  },
  mounted () {
    if (this.$storybridge) {
      this.$storybridge.on(['input', 'published', 'change'], (event) => {
        if (event.action === 'input') {
          // Inject content on the input event
          if (event.story.id === this.page.id) {
            const id = event.story.content.component === menuComponentName
              ? getMenuId(event.story.slug)
              : this.getUrl()
            this.$store.commit('storyblok/story', {
              id,
              data: event.story
            })
          }
        } else if (!event.slugChanged) {
          // Reload the page on save events
          window.location.reload()
        }
      })
    }
    if (this.data.content.secretVipMarket) {
      this.$store.dispatch('member/setCrmMarket', this.data.content.secretVipMarket)
    }
    this.$analytics.pageView(this.page.content.analytics_page_type || 'not set', this.meta.title,
      this.page.content.analytics_page_sub_type || 'not set',
      Math.ceil((Date.now() - new Date(this.page.published_at).getTime()) / (1000 * 60 * 60 * 24)))
  },
  methods: {
    setMeta () {
      if (this.page && this.page.content.seo) {
        const blok = this.page.content
        const description = blok.seo.description || this.settings.default_meta_description
        const canonical = this.$config.baseUrl + (blok.canonical ? this.$u(blok.canonical) : this.$route.path)
        const meta = [
          {
            hid: 'description',
            name: 'description',
            content: description
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: blok.seo.og_title || blok.seo.title || this.page.name
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: blok.seo.og_description || description
          },
          { hid: 'og:url', property: 'og:url', content: canonical },
          { hid: 'og:type', property: 'og:type', content: 'website' },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: this.$config.site.name
          }
        ]

        const robots = blok.metaRobots || null
        if (robots && robots.length) {
          meta.push({
            hid: 'robots',
            name: 'robots',
            content: robots.join(',')
          })
        }

        const ogImage = blok.seo.og_image || this.settings.openGraphImage?.filename || null
        if (ogImage) {
          const resizedImage = this.$imgproxy.transform(ogImage, { width: 1200, height: 630 })
          meta.push({
            hid: 'og:image',
            content: resizedImage,
            property: 'og:image'
          })
        }

        const metaTitle = blok.seo.title || this.page.name
        this.meta.title = this.settings.meta_title_prefix + metaTitle + this.settings.meta_title_suffix
        this.meta.meta = meta
        this.meta.canonical = canonical
      }
    },
    getUrl () {
      if (this.$store.state.route.query._storyblok) {
        // Browsing in SB
        return this.$store.state.route.query._storyblok
      }

      let route = this.$route.path.replace(/^\//, '')
      route = route === '' ? 'home' : route
      route = route.replace(/^[\w-]+\/?/, 'site/')
      return route
    }
  }
}
